import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '../user.model';
import { Subject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  user = new Subject<User>(); //user data subject

  constructor(private http: HttpClient,
    public jwtHelper: JwtHelperService,
     private sharedSer: SharedService) { }


  //login
  login(data: any) {
    return this.http.post(this.sharedSer.ipRoot + '/User/Login', data);
  };

  //register
  register(data: any) {
    return this.http.post(this.sharedSer.ipRoot + '/User/Register', data);
  };

  //forget password
  forgetPassword(data: any) {
    return this.http.post(this.sharedSer.ipRoot + '/User/ForgetPassword?email=' + data, {});
  };

  //verficationCode
  verficationCode(verifyData: any) {
    return this.http.post(this.sharedSer.ipRoot +'/User/CheckCode', verifyData);
  };

  //change-password
  changePassword(data: any) {
    console.log(data);
    return this.http.post(this.sharedSer.ipRoot + '/User/CompletForgetPassword', data);
  }


  //getUserAPi
  getUserApi() {
    return this.http.get(this.sharedSer.ipRoot + '/User');
  }

  //setToken
  setUser(user: User): void {
    localStorage.setItem('user', JSON.stringify(user));
  }

  //getToken
  getUser(): User {
    return JSON.parse( localStorage.getItem('user') || '{}');
  }

  //function to check if is Authenticated or no
  isAuth(): boolean {
      const token = this.getUser().token //token
      if(token){
        return !this.jwtHelper.isTokenExpired(token);
      }
      return false;
  }

}

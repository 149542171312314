import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from '../shared/shared.service';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PagesService {

  constructor(private sharedService: SharedService,
     private http: HttpClient) { }



  karavans = new Subject<any[]>(); //karavans data <Subject>
  suggestedPlaces = new Subject<any[]>(); //Suggested places data <Subject>
  products = new Subject<any[]>(); // product data <subject>
  product = new Subject<any[]>(); //product data in reservation

  filtterSearchKaravanObject = new Subject<{}>();
  filtterSearchSuggestedPlacesObject = new Subject<{}>(); // fillter suggested places subject object
  filterSearchProductObject = new Subject<{}>();

  searchObject: any; //search fillter to send to request for karavan
  searchObjectPlaces: any; //search fillter to send to request for karavan
  searchObjectProducts:any; //search filter to send to request for product

  //get slider
  getSlider() {
    return this.http.get(this.sharedService.ipRoot + '/Slider/GetAll');
  };

  //get brands
  getBrands() {
    return this.http.get(this.sharedService.ipRoot + '/Brand/GetAll');
  }

  //get Reservation Steps
  getReservationSteps() {
    return this.http.get(this.sharedService.ipRoot + '/ReservationStep/GetAll');
  }

  //get country data
  getCountry() {
    return this.http.get(this.sharedService.ipRoot + '/Country/GetAll')
  }

  //get city data
  getCity(countryId: number) {
    return this.http.get(this.sharedService.ipRoot + '/City/CityByContry?countryId=' + countryId);
  }

  //get bestServices
  getServices() {
    return this.http.get(this.sharedService.ipRoot + '/Service/GetAll');
  }

  //get clients
  getClients() {
    return this.http.get(this.sharedService.ipRoot + '/UserReview/GetUserReview');
  }
  //get result of search for karavan
  getKaravansBySearch(searchData: any,pageNo:any) {
    return this.http.post(this.sharedService.ipRoot + '/Carvan/Search?pageSize=6&pageIndex='+pageNo, searchData);
  };

  //get kravan by id
  getCarvanById(data:any) {
    return this.http.get(this.sharedService.ipRoot + '/Carvan/' + data)
  }

  //get all carvans
  getAllCarvan(pageNo:any){
    return this.http.get(this.sharedService.ipRoot + '/Carvan/GetAll?pageSize=6&pageIndex='+pageNo)
  }

  getQuestionAnswer(){
    return this.http.get(this.sharedService.ipRoot + '/Question/GetAll');
  }

  // post contact to server
  postContactUs(data:any){
    return this.http.post(this.sharedService.ipRoot + '/ContactUs' , data )
  }

  getAbout(){
    return this.http.get(this.sharedService.ipRoot + '/AboutUs/GetAll')
  }

  getCompanyDetails(){
    return this.http.get(this.sharedService.ipRoot + '/Company/GetAll' )
  }
  //get all karavans
  getAllKaravansOffers() {
    return this.http.get(this.sharedService.ipRoot + '/Offers/GetAll');
  }

  //post reservation
  postReservation(data:any){
    return this.http.post(this.sharedService.ipRoot + '/Reservations' ,data  )
  }

  //get reservation by id
  getReservationById(id:any){
    return this.http.get(this.sharedService.ipRoot + '/Reservations/' + id  )
  }

  //delete reservation
  deleteReservation(id:any){
    return this.http.delete(this.sharedService.ipRoot + '/Reservations/' + id)
  }

  // edit reservation
  updateReservation(id:any){
    return this.http.delete(this.sharedService.ipRoot + '/Reservations/' + id)
  }

  //set karavans
  serKaravans(karavansData: any[]) {
    this.karavans.next(karavansData);
  }

  //get karavans data
  getKaravans(): any {
   return this.karavans;
  }

  // set product
  setProduct(productData:any[]){
    this.product.next(productData)
  }

  //get product data
  getProduct():any{
    return this.product;
  }

  //get feature
  getAllFeatures(): any{
    return this.http.get(this.sharedService.ipRoot + '/Feature/GetAll')
  }

  //cancel type
  getCancelType():any{
    return this.http.get(this.sharedService.ipRoot + '/CancelType/GetAll')
  }

  //get rules
  getRules():any{
    return this.http.get(this.sharedService.ipRoot + '/Roles/GetAll')
  }

  //get carvan types
  getCarvansType():any{
    return this.http.get(this.sharedService.ipRoot + '/CarvanType/GetAll')
  }

  //get review for carvan by id
  getReviewByCarvanId(id:any):any{
    return this.http.get(this.sharedService.ipRoot + '/UserReview/GetCarvanReview?CarvanId=' + id)
  }

  //get carvan rules by id
  getCarvanRulesById(id:any):any{
    return this.http.get(this.sharedService.ipRoot + '/Roles/GetCarvanRoles?carvanId=' + id)
  }

  //get high rated reviews
  getHighRatedReviews():any{
    return this.http.get(this.sharedService.ipRoot + '/UserReview/GetUserReview')
  }

  //get high rated reviews
  getHighRatedReviewsClient():any{
    return this.http.get(this.sharedService.ipRoot + '/UserReview/GetClientReviews')
  }

  //get reserved dates of carvan
  getReservedDatesById(id:any):any{
    return this.http.get(this.sharedService.ipRoot + '/Reservations/CarvanReservation?CarvanId=' + id)
  }

  //post review
  addNewReview(data:any):any{
    return this.http.post(this.sharedService.ipRoot + '/UserReview/addUserReview' , data);
  }


  // get user reservation
  getUserReservations():any{
    return this.http.get(this.sharedService.ipRoot + '/Reservations/UserReservation')
  }

  //change user password
  changePassword(data:any):any{
    return this.http.put(this.sharedService.ipRoot + '/User/UpdatePassword' ,data)
  }

  //get user information
  getuserData():any{
    return this.http.get(this.sharedService.ipRoot + '/User')
  }

  //get favourite carvans
  getFavouriteCarvans():any{
    return this.http.get(this.sharedService.ipRoot + '/Carvan/GetAllUserFavoritsCaravan')
  }

  //get user carvans
  getUserCarvans():any{
    return this.http.get(this.sharedService.ipRoot+'/Carvan/GetAllUserCaravan')
  }

  //AdditionalFeature
  getAdditionalFeature() {
    return this.http.get(this.sharedService.ipRoot + '/AdditionalFeature/GetAll');
  }

  //get site features
  getSiteFeatures() {
    return this.http.get(this.sharedService.ipRoot + '/SiteFeature/GetAll');
  }

  //get vedio siteShare
  getVedioSite() {
    return this.http.get(this.sharedService.ipRoot + '/SharedVideo/GetAll');
  }

  //shared steps
  sharedSteps() {
    return this.http.get(this.sharedService.ipRoot + '/SharedStep/GetAll')
  }

  //get all places
  getPlaces() {
    return this.http.get(this.sharedService.ipRoot + '/Place/GetAll');
  }

  //get plce by id
  getPlaceId(id: number) {
    return this.http.get(this.sharedService.ipRoot + '/Place/' + id);
  }

  //get CompanySocialMediaLinks
  getSocialMedia() {
    return this.http.get(this.sharedService.ipRoot + '/Company/GetCompanySocialMediaLinks');
  }

  //set favourite / non favourite
  setFavourite(data: any) {
    return this.http.post( this.sharedService.ipRoot + '/Carvan/Favorite', data);
  }

  // set places favourite
  setPlaceFavourite(data: any) {
    return this.http.post(this.sharedService.ipRoot + '/Place/Favorite', data);
  }

  //set product favourite
  setProductFavourite(data: any) {
    return this.http.post(this.sharedService.ipRoot + '/Products/AddToFavorite', data);
  }

  //add product to reserveations
  addProductToResrvition(data: any) {
    return this.http.post(this.sharedService.ipRoot + '/Reservations/AddProductToResrvition', data);
  }

  //updateUserData
  updateUser(data:any){
    return this.http.put(this.sharedService.ipRoot + '/User/UpdateUser', data);
  }

  //get all products
  getProducts(){
    return this.http.get(this.sharedService.ipRoot + '/Products/GetAll')
  }

  //get product by id
  getProductById(id:any){
    return this.http.get(this.sharedService.ipRoot + '/Products/GetJobe?ProductsId=' + id)
  }

  //get products categories
  getProductsCategories(){
    return this.http.get(this.sharedService.ipRoot + '/ProductsCategory/GetAll')
  }

  //search for products
  productFilter(data:any){
    return this.http.post(this.sharedService.ipRoot + '/Products/Search', data)
  }

  //get places Roles
  getPlacesRules() {
    return this.http.get(this.sharedService.ipRoot + '/PlaceRole/GetAll')
  }

  //searchPlaces
  searchPlaces(searchObject: any) {
    return this.http.post(this.sharedService.ipRoot + '/Place/Search', searchObject);
  }

  //add product comment
  addProductReview(data: any) {
    return this.http.post(this.sharedService.ipRoot + '/Products/AddProductsRate', data);
  }

  // payment
  payment(data:any){
    return this.http.post(this.sharedService.ipRoot +'/Payment/Payment',data);
  }

  // get tax by id
  getTaxById(id:any){
    return this.http.get(this.sharedService.ipRoot +`/Tax/${id}`);
  }
}
